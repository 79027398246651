import PchModel from "@/Models/Documents/PchModel";

const documents: PchModel[] = [
    new PchModel(
        'pch64001',
        'รวมงบทดลองประจำปี 2564',
        '',
        'drive.google.com/drive/folders/1XTpnfWBrFwDaEhJK4sZb8nSPrJEYTSIG?usp=share_link',
        new Date(2022, 9, 1, 12, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'pch65001',
        'รวมงบทดลองประจำปี 2565',
        '',
        'drive.google.com/drive/folders/1ZWX0Ig3b1nvOPJkXM8lSPUDR4CXYsL03?usp=share_link',
        new Date(2023, 0, 1, 12, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'pch001',
        'ประกวดราคาแฟลต ๕ชั้น ร้อยตชด.๔๔๕',
        '',
        'drive.google.com/file/d/1scFiswIc0GEAtchjAIgtDomdDCLjDbzS/view?usp=share_link',
        new Date(2022, 10, 25, 12, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'pch66001',
        'รวมงบทดลองประจำปี 2566',
        '',
        'drive.google.com/drive/folders/1o4KXIorsRU3T_k4JG5SziyWnoMy0683sleqscu02TUqCKrTxGpcRU_QJew521pRDiPWjSPGp?usp=share_link',
        new Date(2023, 1, 15, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        '0030.544/1158',
        'ประกาศผู้ชนะการเสนอราคา ซื้อวัสดุเครื่องแต่งกาย(เครื่องแบบนักเรียนอิสลาม)',
        '',
        'drive.google.com/file/d/1V3Zh95Qz-bZtakvlYROIBTXtVQ0I0gDn/view?usp=drive_link',
        new Date(2023, 6, 17, 16, 0, 0, 0).toUTCString()
    ),
   
    new PchModel(
        '0030.544/921',
        'ประกาศผู้ชนะเสนอราคาจัดซื้อครุภัณฑ์ทางการแพทย์ฯ',
        '',
        'drive.google.com/file/d/1ShzCgCD0FfB-vxxqIiwTqlTGxL_dn3mG/view?usp=drive_link',
        new Date(2024, 7 - 1, 1, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        '0030.544/922',
        'ประกาศผู้ชนะเสนอราคาจัดซื้อรถจยย.วิบาก',
        '',
        'drive.google.com/file/d/1KWjGTUrO_E7ZIL1x-u9JEEKu8Ns9rqG2/view?usp=drive_link',
        new Date(2024, 7 - 1, 1, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'พิเศษ',
        'ประกาศผู้ชนะเสนอราคาห่วงรูเลขแปดและเชือกโรยตัว',
        '',
        'drive.google.com/file/d/1fZ6kV0Z80YGbPlqPrRf6y1i1dI8psDXT/view?usp=drive_link',
        new Date(2024, 7 - 1, 11, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'พิเศษ',
        'ประกาศผู้ชนะเสนอราคาเครื่องอัดอากาศ',
        '',
        'drive.google.com/file/d/1oypvEYB4y8G95ojXIY0tbL-7-s5DthYN/view?usp=sharing',
        new Date(2024, 7 - 1, 11, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        '0030.544/1045',
        'ประกาศผู้ชนะเสนอราคาจัดซื้อ เครื่องกำเนิดไฟฟ้า, เครื่องตัดหญ้า และถังไฟเบอร์กลาส',
        '',
        'drive.google.com/file/d/1s5tl1jhunSIIm7WBm1IdvWqnLBdaWiGL/view?usp=sharing',
        new Date(2024, 7 - 1, 17, 7, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        '0030.544/1063',
        'ประกาศแผนการจัดซื้อจัดจ้าง ประจำปีงบประมาณ 2567',
        '',
        'drive.google.com/file/d/1_f3kl-DqQZiWt_ree7rt6LCM1p7LOsV6/view?usp=sharing',
        new Date(2024, 7 - 1, 18, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'พิเศษ',
        'ประกาศแผนการจัดซื้อจัดจ้าง(ขยายเขตไฟฟ้า)',
        '',
        'drive.google.com/file/d/1pgbE0wB-KfR5vmIOd7SSxHPky95bUA8v/view?usp=drive_link',
        new Date(2024, 8 - 1, 1, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'พิเศษ',
        'ประกาศแผนการจัดซื้อจัดจ้าง(โรงจอดรถ)',
        '',
        'drive.google.com/file/d/1e5C4eAxcA_Iu2HxPGRMHrV_ofO720tQ3/view?usp=drive_link',
        new Date(2024, 8 - 1, 5, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'พิเศษ',
        'ประกาศผู้ชนะเสนอราคาจ้างขยายเขตปรับปรุงระบบไฟฟ้า',
        '',
        'drive.google.com/file/d/1shYwmmOGQBTOU4G_ReBsn8euf9CR9SGH/view?usp=sharing',
        new Date(2024, 8 - 1, 6, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'พิเศษ',
        'ประกาศผู้ชนะเสนอราคาปรับปรุงโรงจอดรถ',
        '',
        'drive.google.com/file/d/1UVx7MVt9jyTvyFx1ODS0uUizjxMLWrH4/view?usp=sharing',
        new Date(2024, 9 - 1, 2, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'พิเศษ',
        'ประกาศผู้ชนะเสนอราคาจ้างก่อสร้างอาคาร ร้อยตชด.๔๔๙',
        '',
        'drive.google.com/file/d/1D16Uth37mDvRRlzYHQ2GV7FkZoNVn9YZ/view?usp=sharing',
        new Date(2024, 9 - 1, 11, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'pch67001',
        'รวมงบทดลองประจำปี 2567',
        '',
        'drive.google.com/drive/u/2/folders/1zHpp3_7nOJgo3lqKWhM6O_UZMNjuFdf8',
        new Date(2024, 10, 1, 9, 0, 0, 0).toUTCString()
    ),
    new PchModel(
        'pch68001',
        'รวมงบทดลองประจำปี 2568',
        '',
        'drive.google.com/drive/u/1/folders/1Mf3qvYMDFxbkVQg7Hhy-wFGdLCr49o6u',
        new Date(2025, 0, 30, 9, 0, 0, 0).toUTCString()
    ),
]


export default documents;